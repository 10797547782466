<template>
    <v-main class="testClass">
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="5" class="text-center">
                    <h2 class="white--text">404 - Page not nound</h2>
                    <v-btn depressed tile router to="/" class="mt-5">GO TO HOME PAGE</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    created() {
        window.location.href = 'https://admin.balkanac.net'
    }
}
</script>

<style>
.testClass {
    background: #446e9b;
}
</style>
